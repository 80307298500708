.App {
  text-align: center;
}
/* * h1,h2,h3,h6,h5,h4:no {
  text-align: right !important;
} */



.iran-united-img{
  width: 24px;
  height: auto;
}




.active {
  background-color: #054976 !important;
  color: white !important;
}


.row-Specialties {
  
}


.rastchin-theme .header-title {
  text-align: right;
}

.rastchin-theme .about-caption {
  text-align: center;
}

.rastchin-theme .header-content {
  text-align: right;
  width: 100%;
  padding: 34px !important;
}
.rastchin-theme .header-subtitle {
  letter-spacing: 2px !important;
}

.rastchin-theme .hire-me {
  text-align: center;
}

.rastchin-theme .form-group input
,.rastchin-theme .form-group textarea {
  text-align: end;
}
.rastchin-theme  {
  font-family: 'IRANYekan', sans-serif;
}

.dark .card-projects-div {
  background-color: #000224;     
}
.dark .span-project-secondary2 {
  color: #dcedff;
}
.dark .see-more-projects {
  color: black;
}

.rastchin-theme *  {
  font-family: 'IRANYekan', sans-serif;
}
.rastchin-theme .logo {
  font-family: 'Baloo Paaji', cursive ;
}

.rastchin-theme [class^="ti-"],
.rastchin-theme [class*=" ti-"] {
  font-family: 'themify';
}

.rastchin-theme .custom-navbar .nav .item .link {
  font-weight: normal;
}

.rastchin-theme .header-title {
  margin-bottom: 2.5rem;
}
.rastchin-theme .header .header-content .header-title .down {
  font-size: 46px;
  margin : 1rem 0rem ;
}
.rastchin-theme .header .header-content .header-title .up {
  font-size: 32px;
}

.swiper-scrollbar {
  background: rgb(213 213 213 / 9%) !important;
}
.swiper-scrollbar-drag {
  background: rgb(147 193 213 / 35%) !important;
}
.dark .swiper-scrollbar-drag {
  background: var(--swiper-scrollbar-drag-bg-color, rgba(0, 0, 0, 0.5)) !important;
}
.dark .swiper-scrollbar-drag {
  background: var(--swiper-scrollbar-bg-color, rgba(0, 0, 0, 0.1)) !important;
}

