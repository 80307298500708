.contact-footer {
    margin-left: 1rem !important;
}

.swiper-card {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;
}

.flip-card {
    width: 95%;
    height: 270px;
    perspective: 550px;
    padding: 8px 4px;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.flip-card img {
    height: auto;
    width: 100%;
}

.projects-secondary-images {
    height: 70px;
    width: 70px;
    background: transparent;
    border-radius: 1rem;
}


.card-projects-div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 12px;
    margin: auto;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    margin-bottom: 18px;
    border-radius: 8px;
    background-color: #f8f8f8;
}

.main-secondary-project {
    height: 100%;
    width: 100%;
}

.span-project-secondary1 {
    height: 90px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #3a557d;
    border-radius: 8px;
}
.span-project-secondary2 {
    height: 70px;
    width: 49%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.see-more-projects {
    background: #b3cde2;
    height: 40px;
    width: 9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border-radius: 4% 20px;
    box-shadow: 1px 1px 3px 0px #074796;
}

.see-more-projects:hover {
    background: #87a5be;
    box-shadow: 0px 0px 0px 0px black;
}
.see-more-projects:active {
    background: #87a5be;
    box-shadow: 0px 0px 0px 0px black;
}


.span-project-secondary3 {
    height: 65px;
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #cac8c8;
}

.nexix{
    position: fixed;
    right: 8px;
    list-style: none;
    display: flex;
    justify-content: center;
    width: 50%;
    height: 66%;
    padding: 0;
    margin: auto;
    max-width: 300px;
}

.nexix li {
    height: 100%;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.nexix li * {
    width: 40px !important;
    cursor: pointer;
}


.swiper {
    height: 340px !important;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
    top: 90%;
}

.swiper-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    top: 90%;
}

.swiper-horizontal>.swiper-scrollbar,
.swiper-scrollbar.swiper-scrollbar-horizontal {
    height: 12px !important;
    width: 60% !important;
    left: 20% !important;
}

.swiper-scrollbar-drag {
    /* box-shadow: #dddddd 1px 0px 6px -4px; */
}

.khate-poshte-card {
    height: 5px;
    width: 60%;
    background-color: var(--h1-fc);
    transform: translateY(250px);
    opacity: 0;
    transition-delay: 1s;
    transition: transform 1s ease-in-out;
}

.text-poshte-card {
    width: 95%;
    color: var(--h1-fc);
    height: 60%;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: start;
    padding-top: 4px;
    transform: translateY(250px);
    opacity: 0;
    transition-delay: 1s;
    transition: transform 1s ease-in-out;
}


.portfolio-pic-butt1 {
    height: 50px;
    width: 110px;
    border: 0px;
    /* display: block; */
    outline: 0;
    border-radius: 20% 80% 77% 23% / 100% 48% 52% 0%;
    box-shadow: 0px 0px 20px #aaaaaa,
        -0px -0px 20px #ffffff;
}

.portfolio-pic-butt2 {
    height: 50px;
    width: 110px;
    border: 0px;
    /* display: block; */
    outline: 0;
    border-radius: 80% 20% 23% 77% / 48% 100% 0% 52%;
    box-shadow: 0px 0px 20px #aaaaaa,
        -0px -0px 20px #ffffff;
}

.card-customize-title {
    transform: translateY(250px);
    opacity: 0;
    transition-delay: 1s;
    transition: transform 1s ease-in-out;
}

.swiper-horizontal>.swiper-scrollbar,
.swiper-scrollbar.swiper-scrollbar-horizontal {}

.swiper-scrollbar {
    position: absolute;
    left: var(--swiper-scrollbar-sides-offset, 1%);
    bottom: var(--swiper-scrollbar-bottom, 4px);
    top: var(--swiper-scrollbar-top, auto);
    z-index: 50;
    height: var(--swiper-scrollbar-size, 4px);
    width: calc(100% - 2* var(--swiper-scrollbar-sides-offset, 1%));
}

.flip-card-inner {
    position: relative;
    height: 100%;
    width: 100%;
    text-align: center;
    transition: transform 1s;
    transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card:hover .narm-opac {
    transform: translateY(0);
    opacity: 1;
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    height: 100%;
    width: 100%;
    backface-visibility: hidden;

}

.flip-card-front {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

.flip-card-back {
    /* background-color: lightblue; */
    color: white;
    transform: rotateY(180deg);
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    padding: 1rem .5rem;
    overflow: hidden;
}