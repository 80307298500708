.secondaries {
    margin: 7rem 1rem 1rem;
    text-align: right;
}

.secondary-main-section {
    flex-wrap: wrap-reverse;
    /* align-items: baseline; */
    justify-content: right;
}

.customize-h2-secondary {
    text-align: center;
    font-weight: bold;
    font-size: 46px;
}
.custom-navbar .nav-second {
    min-height: 70px;
}

.secondary-sidebar {
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.secondary-sidebar-searchbox {
    position: relative;
    margin-bottom: 1rem;
}

.secondary-search-input {
    direction: rtl;
    padding: 4px 8px;
    background-color: transparent;
    border-radius: 8px;
    border: 1px solid #676767;
}

.secondary-search-icon {
    position: absolute;
    height: 100%;
    width: 20%;
    top: 0%;
    left: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--icon-color);
}

.secondary-sidebar-list {
    border-right: 1px solid var(--icon-color);
    padding-right: 40px;
}

.list-main-sidebar {
    color: white;
    list-style: none;
    padding-left: 3rem;
    position: relative;
    line-height: 36px;
}

.list-item-sidebar::after {
    content: '';
    position: absolute;
    width: 14px;
    height: 4px;
    background-color: var(--icon-before);
    border-radius: 14px;
    transform: translate(10px, 20px);
}

.list-item-sidebar:hover {
    font-weight: bold;
    transform: translate(-3px, 0px);
    font-size: 18px;
}


.list-item-sidebar {}

.secondary-content-main {
    height: auto;
    max-height: 800px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-end;
}

.secondary-content-head {
    padding: 1rem 3rem;
}

.secondary-content-body {
    height: 100%;
    width: 100%;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding-right: 8px;
    overflow: auto;
}
.secondary-content-body::-webkit-scrollbar {
    /* height: 10%; */
    width: 1rem;
    
}
.secondary-content-body::-webkit-scrollbar-button {
    
}
.logo-secondary {
    color: #695aa6 !important;
}

.blog-boxes-main {
    margin: 1rem .2rem;

}

.main-resume-secpage {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

}

.resume-image-div {
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.resume-image {
    height: auto;
    width: 100%;
    margin-bottom: 18px;
    border-radius: 4px;
    border: 1px solid #2b74a6;
}
@media only screen and (min-width : 750px) {
    .resume-image {
        height: auto;
        width: 90%;
    }
}
@media only screen and (min-width : 850px) {
    .resume-image {
        height: auto;
        width: 80%;
    }
}
@media only screen and (min-width : 950px) {
    .resume-image {
        height: auto;
        width: 70%;
    }
}
@media only screen and (min-width : 1150px) {
    .resume-image {
        height: auto;
        width: 60%;
    }
}
@media only screen and (min-width : 1244px) {
    .resume-image {
        height: auto;
        width: 50%;
    }
}

.div-resume-ask {
    display: flex;
    width: 80%;
    height: 75px;
    justify-content: space-evenly;
    align-items: center;
    margin: 1rem 1rem;
}

.resume-ask {
    padding: 1rem 2rem;
    border: 1px solid;
    border-radius: 8px;
}



/* /////////////////rastchin  */



.rastchin-theme .secondary-main-section {}

.rastchin-theme .secondary-sidebar {}

.rastchin-theme .secondary-sidebar-searchbox {}

.rastchin-theme .secondary-search-input {}

.rastchin-theme .secondary-search-icon {}

.rastchin-theme .secondary-sidebar-list {}

.rastchin-theme .list-main-sidebar {}

.rastchin-theme .list-item-sidebar {}

.rastchin-theme .secondary-content-main {}

.rastchin-theme .secondary-content-head {}

.rastchin-theme .secondary-content-body {}